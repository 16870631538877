import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';

const ContactTemplate = ({ data }) => {
  if (!data) return null;

  const contactContent = data.prismicContact;
  const contact = contactContent.data || {};

  const { lang, type, url } = contactContent;
  const alternateLanguages = contactContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};
  const metaTitle = contactContent.data.meta_title || `${contactContent.data.display_title.text} | CloudArmy`;

  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <SliceZone slices={contact.body} />
    </Layout>
  );
};

export const query = graphql`
  query ContactQuery($uid: String, $lang: String, $id: String) {
    prismicContact(uid: {eq: $uid}, id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        meta_title
        display_title {
          raw
          html
          text
        }
        body {
          ... on PrismicContactDataBodyForm {
            id
            slice_label
            slice_type
            primary {
              description {
                html
                raw
                text
              }
              description_color
              fields
              logo_above_text
              title {
                html
                raw
                text
              }
              title_color
              form_id
              portal_id
              google_conversion_name
            }
            items {
              how_did_you_hear_about_us
            }
          }
          ... on PrismicContactDataBodySocialIcons {
            id
            slice_label
            slice_type
            items {
              icon {
                alt
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              url {
                url
              }
            }
          }
          ... on PrismicContactDataBodyTextWithAction {
            id
            slice_type
            slice_label
            items {
              link_title {
                html
                raw
                text
              }
              link {
                url
                document {
                  ... on PrismicPage {
                  url
                }
                ... on PrismicBlog {
                  url
                }
                ... on PrismicPodcast {
                  url
                }
                ... on PrismicKnowledgeCenter {
                  url
                }
                ... on PrismicContact {
                  url
                }
                }
              }
            }
            primary {
              alignment
              title_color
              text_width
              title {
                html
                raw
                text
              }
              text {
                html
                raw
                text
              }
              logo {
                alt
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicContactDataBodyTextWithDivider {
            id
            slice_label
            slice_type
            items {
              location {
                html
                raw
                text
              }
            }
            primary {
              email {
                html
                raw
                text
              }
              phone_number {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
    prismicNavigation(lang: {eq: $lang}) {
    ...NavigationFragment
    }
    prismicFooter(lang: {eq: $lang}) {
      ...FooterFragment
    }
  }
`;

export default withPrismicPreview(ContactTemplate);
